.advantages {
    margin-top: 180px;
    position: relative;

    &::before {
        position: absolute;
        content: url('../img/page-line.svg');
        left: 50%;
        transform: translateX(-50%);
        top: 155px;
        z-index: -1;
        pointer-events: none;
    }
}

.container {}

.advantages__title {
    text-align: center;
    width: fit-content;
    margin: 0 auto;
    position: relative;

    &::before {
        position: absolute;
        content: url('../img/advantages-title-left.svg');
        left: -354px;
        top: -167px;
    }

    &::after {
        position: absolute;
        content: url('../img/advantages-title-right.svg');
        right: -351px;
        top: -150px;
    }
}

.title {}

.advantages__wrapper {
    margin-top: 60px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 70px;
    column-gap: 20px;
}

.circle-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.circle-col__img {
    width: 190px;
    height: 190px;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
    }
}

.circle-col__title {
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
    margin-top: 20px;
}

.circle-col__descr {
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    margin-top: 20px;
}

@media (max-width: 920px) {
    .advantages__wrapper {
        grid-template-columns: repeat(2, 1fr);
    }

    .circle-col__img {
        width: 140px;
        height: 140px;
    }

    .advantages {
        margin-top: 110px;
    }

    .advantages::before {
        opacity: 0.3;
    }
}

@media (max-width: 660px) {
    .advantages__wrapper {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 40px;
    }
}

@media (max-width: 580px) {
    .circle-col__descr {
        font-size: 18px;
        margin-top: 10px;
    }

    .advantages {
        margin-top: 70px;
    }

    .advantages__wrapper {
        margin-top: 50px;
    }

}