.question {
    margin-top: 163px;
    position: relative;

    &::before {
        position: absolute;
        content: url('../img/question-line.svg');
        left: 52%;
        transform: translateX(-50%);
        top: -343px;
        z-index: -1;
    }
}



.question__title {}

.title {}

.question__wrapper {
    display: flex;
    position: relative;
    margin-top: 60px;
}

.question__img {
    position: absolute;
    left: -78px;
    top: 48px;
    transform: scale(var(--scale3)) rotate(var(--rotate3));
    will-change: transform;
    pointer-events: none;
}

.question__list {
    max-width: 760px;
    width: 100%;
    margin-left: auto;
    background-color: #fff;
}

.toggle__item {
    border-bottom: 2px solid #F05A2C;
    padding-bottom: 20px;
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0;
    }
}

.toggle__item-title {
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &::before {
        content: '';
        background-image: url('../img/acc-icon.svg');
        background-repeat: no-repeat;
        background-size: cover;
        width: 64px;
        min-width: 64px;
        height: 64px;
        display: block;
        margin-left: 5px;
        margin-right: 20px;
        transition: all 0.3s ease-in-out;
    }

    &.toggle__item-title-active {
        &::before {
            transform: rotate(90deg);
        }
    }
}

.toggle__item-descr {
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: 91px;
    display: none;
}


@media (max-width: 1200px) {
    .question__img {
        opacity: 0.2;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .question::before {
        opacity: 0.4;
    }

    .question__list {
        max-width: 100%;
    }
}

@media (max-width: 820px) {
    .question {
        margin-top: 90px;
    }

    .toggle__item-title::before {
        width: 54px;
        min-width: 54px;
        height: 54px;
    }
}

@media (max-width: 580px) {
    .question {
        margin-top: 70px;
    }

    .question__list {
        background-color: transparent;
    }

    .question__wrapper {
        margin-top: 40px;
    }

    .toggle__item-title {
        font-size: 18px;
        line-height: 26px;
    }

    .toggle__item-descr {
        font-size: 16px;
        padding-left: 0;
    }

    .toggle__item-title::before {
        width: 44px;
        min-width: 44px;
        height: 44px;
    }
}