.nutrition {
    margin-top: 212px;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        background-image: url('../img/nutrition-bg.svg');
        background-repeat: no-repeat;
        background-size: 100% auto;
        width: 100%;
        height: 500%;
        z-index: -1;
        top: -300px;
    }
}

.nutrition__wrapper {
    background: #FFFFFF;
    border-radius: 24px;
    max-width: 1104px;
    margin: 0 auto;
    padding: 100px 120px 60px;
    position: relative;
}

.nutrition-decor {
    position: absolute;
}

.nutrition-decor {
    transform: scale(var(--scale)) rotate(var(--rotate));
    will-change: transform;
}

.decor1 {
    left: -147px;
    top: -107px;
    z-index: -1;
}

.decor2 {
    right: -50px;
    top: -49px;
}

.decor3 {
    right: -117px;
    bottom: -101px;
    transform: scale(var(--scale2)) rotate(var(--rotate2));
    will-change: transform;
}


.nutrition__title {
    text-align: center;
    color: #000000;
    margin-bottom: 60px;
}

.title {}

.nutrition-item {
    margin-bottom: 38px;

    &:last-child {
        margin-bottom: 0;
    }
}

.nutrition-item__top {

    font-weight: 400;
    font-size: 46px;
    line-height: 51px;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 2;

    &::before {
        position: absolute;
        content: '';
        border-bottom: 1px dashed #000000;
        width: 100%;
        bottom: 14px;
        z-index: -1;
    }

    div {
        background: #FFFFFF;
    }
}

.nutrition-item__name {
    padding-right: 10px;
    font-family: 'Neucha';
}

.nutrition-item__time {
    padding-left: 10px;
    font-family: 'Neucha';
}

.nutrition-item__descr {
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
    margin-top: 20px;
}


@media (max-width: 880px) {
    .nutrition__wrapper {
        padding: 50px 40px 60px;
    }

    .nutrition-item__top {
        font-size: 40px;
        line-height: 35px;
    }

    .nutrition-item__descr {
        font-size: 18px;
        line-height: 27px;
    }
}

@media (max-width: 580px) {
    .nutrition-item__top {
        font-size: 30px;
        line-height: 32px;
    }

    .nutrition__title {
        margin-bottom: 40px;
    }

    .nutrition-item__descr {
        font-size: 16px;
    }

    .nutrition__wrapper {
        padding: 50px 20px;
    }

    .decor2 {
        right: -50px;
        top: -120px;
    }

    .decor1 {
        left: -63px;
        top: -194px;
    }

    .decor3 {
        right: -40px;
        bottom: -157px;
    }

    .nutrition {
        .container {
            padding: 0;
        }
    }

    .nutrition::before {
        width: 100%;
        height: 279%;
        top: -83%;
        background-size: cover;
    }
}