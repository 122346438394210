@mixin scroll-hide {
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
}

@mixin list-scroll {
    scrollbar-color: #222332 #fff;
    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
    &::-webkit-scrollbar {
        height: 10px;
        background-color: #FFF;
    }
    &::-webkit-scrollbar-thumb {
        background: #696969;
    }
}