:root {
  --color-black: #000;
  --color-white: #fff;
}

@font-face {
  font-family: "Neucha";
  src: url("../fonts/Neucha.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter-Medium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Comfortaa";
  src: url("../fonts/Comfortaa-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Comfortaa";
  src: url("../fonts/Comfortaa-Bold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:focus,
:active {
  outline: none;
}

a:focus,
a:active {
  outline: none;
}

body {
  box-sizing: border-box;
}

aside,
nav,
footer,
header,
section {
  display: block;
}

input::-ms-clear {
  display: none;
}

button {
  cursor: pointer;
  border: none;
  background-color: transparent;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

a,
a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  color: inherit;
}

ul li {
  list-style: none;
}

dl,
ol,
ul {
  margin: 0;
  padding: 0;
}

label {
  margin: 0;
}

p {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit;
  font-size: inherit;
  margin: 0;
}

input:not([type=checkbox]):not([type=radio]),
textarea,
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

* {
  font-family: "Comfortaa", sans-serif;
}

body {
  color: #000;
}
body.scroll-hide {
  overflow: hidden;
}

.container {
  max-width: 1310px;
  margin: 0 auto;
  padding: 0 15px;
}

.page {
  position: relative;
  overflow: hidden;
  min-height: 100vh;
}

.btn {
  background: linear-gradient(92.36deg, #F05A2C -16.09%, #EC6838 46.59%, #FDB041 113.31%);
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 22px;
  line-height: 25px;
  color: #FFFFFF;
  padding: 20px 55px;
  transition: all 0.3s ease-in-out;
  position: relative;
}
.btn::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 24px;
  border: 3px solid #F05A2C;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.btn:hover {
  box-shadow: 4px 6px 15px rgba(240, 90, 44, 0.3);
}
.btn:active::before {
  opacity: 1;
}

.title {
  font-family: "Neucha";
  font-weight: 400;
  font-size: 46px;
  line-height: 51px;
  text-transform: uppercase;
  color: #F05A2C;
}

.container-slider {
  max-width: 1400px;
  padding: 0 60px;
}
.container-slider .swiper-button-next {
  right: -50px;
}
.container-slider .swiper-button-prev {
  left: -50px;
}
.container-slider .swiper-button-next:after,
.container-slider .swiper-button-prev:after {
  color: #F05A2C;
  font-weight: 900;
  font-size: 37px;
}
.container-slider .swiper-pagination-bullet {
  background: #FDB041;
  opacity: 1;
  width: 10px;
  height: 10px;
  margin: 0 5px !important;
}
.container-slider .swiper-pagination-bullet-active {
  background: #F05A2C;
}

.header {
  margin-top: 25px;
}

.header__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.header__wrapper::before {
  position: absolute;
  content: url("../img/header-line.svg");
  left: 50%;
  transform: translateX(-50%);
  top: 87%;
}

.nav__list {
  display: flex;
  align-items: center;
}

.nav__item {
  margin-right: 40px;
}
.nav__item:last-child {
  margin-right: 0;
}

.nav__link {
  font-family: "Neucha";
  font-weight: 400;
  font-size: 22px;
  line-height: 24px;
  color: #000;
  position: relative;
}
@media (min-width: 1024px) {
  .nav__link:hover::before {
    transform: translateY(0);
    opacity: 2;
  }
}
.nav__link::before {
  position: absolute;
  content: "";
  width: 100%;
  background-color: #F05A2C;
  height: 2px;
  bottom: 0;
  left: 0;
  transform: translateY(5px);
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.phone-link {
  font-family: "Neucha";
  font-weight: 400;
  font-size: 22px;
  line-height: 24px;
  text-transform: capitalize;
  color: #000;
  position: relative;
}
@media (min-width: 1024px) {
  .phone-link:hover::before {
    transform: translateY(0);
    opacity: 2;
  }
}
.phone-link::before {
  position: absolute;
  content: "";
  width: 100%;
  background-color: #F05A2C;
  height: 2px;
  bottom: 0;
  left: 0;
  transform: translateY(5px);
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.burger {
  height: 16px;
  width: 30px;
  justify-content: center;
  align-items: center;
  z-index: 350;
  cursor: pointer;
  transition: all 0.3s;
  display: none;
  position: relative;
}
.burger span {
  position: absolute;
  background: #EC6838;
  height: 3px;
  width: 30px;
  display: block;
  transition: all 0.3s;
  border-radius: 5px;
}
.burger span:nth-child(1) {
  top: 16px;
}
.burger span:nth-child(2) {
  top: 50%;
  transform: translateY(-50%);
}
.burger span:nth-child(3) {
  bottom: 16px;
}
.burger.active span:nth-child(1) {
  top: 6px;
  transform: rotate(45deg);
}
.burger.active span:nth-child(2) {
  opacity: 0;
}
.burger.active span:nth-child(3) {
  bottom: 7px;
  transform: rotate(-45deg);
}

@media (max-width: 1280px) {
  .nav__item {
    margin-right: 20px;
  }
}
@media (max-width: 1150px) {
  .header {
    margin-top: 10px;
    position: fixed;
    background-color: rgba(255, 255, 255, 0);
    width: 100%;
    z-index: 222;
    margin-top: 0;
    padding: 10px 0 0 0;
    transition: all 0.3s ease-in-out;
  }
  .header.active {
    background-color: #fff;
  }
  .header .nav {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: #F6D354;
    z-index: 100;
    padding: 50px 20px;
    transform: translateY(-100%);
    transition: all 0.3s ease-in-out;
  }
  .header .nav.active {
    transform: translateY(0);
  }
  .header .nav__list {
    flex-direction: column;
  }
  .header .phone-link {
    z-index: 2;
    margin: 0 auto;
    display: block;
    text-align: center;
  }
  .header .nav__item {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .header .burger {
    display: block;
  }
  .header .header__wrapper::before {
    display: none;
  }
  .header .logo {
    height: 68px;
  }
}
.promo {
  margin-top: 185px;
  position: relative;
}
.promo::before {
  content: "";
  position: absolute;
  background-image: url("../img/promo-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 400%;
  z-index: -1;
  top: -135%;
}

.promo__img {
  position: absolute;
  left: 49.5%;
  top: 70%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.promo__wrapper {
  max-width: 706px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  text-align: center;
}

.promo__title {
  font-family: "Neucha";
  font-weight: 400;
  font-size: 84px;
  line-height: 93px;
  text-transform: uppercase;
  color: #F05A2C;
  letter-spacing: 12px;
  display: flex;
}
.promo__title span {
  transform: translateY(-27px);
  display: block;
}

.promo__subtitle {
  font-weight: 500;
  font-size: 22px;
  line-height: 25px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-top: 5px;
}

.promo__descr {
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  color: #000;
  margin-top: 40px;
}

.promo__btn {
  margin: 60px auto 0 auto;
}

@media (max-width: 860px) {
  .promo__img {
    display: none;
  }

  .promo__title {
    font-size: 72px;
  }

  .promo__subtitle {
    font-size: 18px;
  }

  .promo__descr {
    font-size: 18px;
    margin-top: 20px;
  }

  .promo {
    margin-top: 120px;
  }

  .btn {
    font-size: 18px;
    border-radius: 20px;
    padding: 16px 40px;
  }

  .promo__btn {
    margin: 40px auto 0 auto;
  }
}
@media (max-width: 580px) {
  .promo__title {
    font-size: 62px;
  }

  .promo__subtitle {
    font-size: 16px;
  }

  .promo__descr {
    font-size: 16px;
    line-height: 26px;
  }

  .btn {
    font-size: 16px;
    border-radius: 15px;
    padding: 15px 30px;
  }

  .promo::before {
    width: 100%;
    height: 279%;
    top: -83%;
    background-size: cover;
  }
}
.about {
  margin-top: 200px;
}
.about .container {
  padding-left: 123px;
  position: relative;
}
.about .container::before {
  position: absolute;
  content: url("../img/about-line.svg");
  left: -63px;
  top: -184px;
  pointer-events: none;
}

.about__wrapper {
  margin-top: 46px;
  display: flex;
  position: relative;
}

.about__descr {
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  color: #000;
  max-width: 815px;
}
.about__descr p {
  margin-bottom: 20px;
}
.about__descr p:last-child {
  margin-bottom: 0;
}

.about__img {
  position: absolute;
  right: -13px;
  top: -36px;
  transform: translateY(var(--position));
  will-change: transform;
  z-index: -1;
}

@media (max-width: 1260px) {
  .about__img {
    opacity: 0.5;
  }
}
@media (max-width: 1000px) {
  .about .container::before {
    opacity: 0.3;
  }

  .about__img {
    opacity: 0.3;
  }

  .about .container {
    padding-left: 15px;
  }

  .about {
    margin-top: 100px;
  }

  .about__descr {
    font-size: 18px;
  }

  .about__wrapper {
    margin-top: 20px;
  }
}
@media (max-width: 580px) {
  .title {
    font-size: 38px;
    line-height: 44px;
  }

  .about__descr {
    line-height: 25px;
    font-size: 16px;
  }

  .about .container::before {
    opacity: 0.2;
  }

  .about__img {
    opacity: 0.1;
  }
}
.everyday-life {
  margin-top: 100px;
}
.everyday-life .everyday-life__container {
  padding-left: 123px;
}

.everyday-life__subtitle {
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  color: #000;
  margin-top: 20px;
}

.everyday-life__list {
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(4, auto);
  row-gap: 40px;
  column-gap: 25px;
  max-width: 1085px;
}
.everyday-life__list li {
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  color: #000;
  padding-left: 26px;
  position: relative;
}
.everyday-life__list li::before {
  content: "";
  position: absolute;
  background-image: url("../img/list-icon.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 20.13px;
  height: 19.5px;
  left: 0;
  top: 4px;
}

.everyday-life__slider {
  margin-top: 60px;
  position: relative;
}
.everyday-life__slider .swiper {
  padding-bottom: 40px;
}
.everyday-life__slider .swiper-button-next,
.everyday-life__slider .swiper-button-prev {
  top: 42%;
}
.everyday-life__slider .swiper-slide {
  overflow: hidden;
  border-radius: 24px;
}
.everyday-life__slider .swiper-slide img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  border-radius: 24px;
  transition: all 2s ease-in-out;
}
.everyday-life__slider .swiper-slide img:hover {
  transform: scale(1.1);
}

@media (max-width: 1000px) {
  .everyday-life .everyday-life__container {
    padding-left: 15px;
  }

  .everyday-life__subtitle {
    font-size: 18px;
  }

  .everyday-life__list li {
    font-size: 18px;
  }

  .everyday-life__list {
    margin-top: 20px;
  }
}
@media (max-width: 930px) {
  .everyday-life__list {
    grid-template-columns: repeat(3, auto);
    row-gap: 20px;
  }
}
@media (max-width: 580px) {
  .everyday-life__list {
    grid-template-columns: repeat(1, auto);
    row-gap: 20px;
  }

  .everyday-life {
    margin-top: 70px;
  }

  .everyday-life__slider .swiper-button-next,
.everyday-life__slider .swiper-button-prev {
    display: none;
  }

  .container-slider {
    padding: 0 15px;
    margin-top: 30px;
  }

  .everyday-life .container-slider {
    margin-top: 30px;
  }

  .everyday-life__slider {
    margin-top: 30px;
  }
}
.advantages {
  margin-top: 180px;
  position: relative;
}
.advantages::before {
  position: absolute;
  content: url("../img/page-line.svg");
  left: 50%;
  transform: translateX(-50%);
  top: 155px;
  z-index: -1;
  pointer-events: none;
}

.advantages__title {
  text-align: center;
  width: fit-content;
  margin: 0 auto;
  position: relative;
}
.advantages__title::before {
  position: absolute;
  content: url("../img/advantages-title-left.svg");
  left: -354px;
  top: -167px;
}
.advantages__title::after {
  position: absolute;
  content: url("../img/advantages-title-right.svg");
  right: -351px;
  top: -150px;
}

.advantages__wrapper {
  margin-top: 60px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 70px;
  column-gap: 20px;
}

.circle-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.circle-col__img {
  width: 190px;
  height: 190px;
}
.circle-col__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.circle-col__title {
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  margin-top: 20px;
}

.circle-col__descr {
  font-weight: 400;
  font-size: 20px;
  line-height: 22px;
  margin-top: 20px;
}

@media (max-width: 920px) {
  .advantages__wrapper {
    grid-template-columns: repeat(2, 1fr);
  }

  .circle-col__img {
    width: 140px;
    height: 140px;
  }

  .advantages {
    margin-top: 110px;
  }

  .advantages::before {
    opacity: 0.3;
  }
}
@media (max-width: 660px) {
  .advantages__wrapper {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 40px;
  }
}
@media (max-width: 580px) {
  .circle-col__descr {
    font-size: 18px;
    margin-top: 10px;
  }

  .advantages {
    margin-top: 70px;
  }

  .advantages__wrapper {
    margin-top: 50px;
  }
}
.form-sec {
  margin-top: 230px;
  position: relative;
}
.form-sec::before {
  content: "";
  position: absolute;
  background-image: url("../img/form-sec-bg.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  width: 100%;
  height: 300%;
  z-index: -1;
  top: -60%;
}
.form-sec .container {
  position: relative;
}
.form-sec .container::before {
  position: absolute;
  content: url("../img/form-sec-icon.png");
  left: -67px;
  top: -169px;
}
.form-sec.form-sec--two {
  margin-top: 350px;
}
.form-sec.form-sec--two .container::before {
  display: none;
}
.form-sec.form-sec--two .form-sec__wrapper {
  margin-top: 60px;
}
.form-sec.form-sec--two .form-sec__left .form {
  margin-top: 20px;
}

.form-sec__wrapper {
  display: flex;
  justify-content: space-between;
}

.form-sec__left {
  max-width: 630px;
  width: 100%;
}
.form-sec__left .form {
  margin-top: 35px;
}

.form-sec__subtitle {
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  color: #F05A2C;
  margin-top: 10px;
  max-width: 546px;
}

.form {
  width: 100%;
}

.form__input {
  width: 100%;
  height: 80px;
  margin-bottom: 20px;
}
.form__input input {
  background: rgba(255, 255, 255, 0.6);
  border-radius: 24px;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  color: #F05A2C;
  border: 1px solid #F05A2C;
}
.form__input input::placeholder {
  color: #F05A2C;
}
.form__input select {
  background: rgba(255, 255, 255, 0.6);
  border-radius: 24px;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  padding-right: 50px;
  color: #F05A2C;
  border: 1px solid #F05A2C;
}

.form__select {
  position: relative;
}
.form__select::before {
  content: "";
  position: absolute;
  background-image: url("../../img/arrow.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 24px;
  height: 14px;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.form__btn {
  margin-top: 40px;
}

.form-sec__img {
  transform: translateX(var(--positionX));
  will-change: transform;
}

@media (max-width: 1000px) {
  .form-sec__img {
    width: 400px;
  }
  .form-sec__img img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }

  .form-sec__wrapper {
    align-items: center;
  }

  .form-sec.form-sec--two {
    margin-top: 180px;
  }

  .form-sec.form-sec--two .form-sec__wrapper {
    margin-top: 40px;
  }
}
@media (max-width: 820px) {
  .form__input {
    height: 70px;
  }

  .form-sec__img {
    opacity: 0.1;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
  }

  .form-sec .container::before {
    opacity: 0.4;
  }

  .form-sec__wrapper {
    position: relative;
  }

  .form-sec {
    margin-top: 110px;
  }
}
@media (max-width: 580px) {
  .form__input input {
    font-size: 18px;
    border-radius: 15px;
    background: rgba(255, 255, 255, 0.8);
  }

  .form__input select {
    font-size: 18px;
    border-radius: 15px;
    background: rgba(255, 255, 255, 0.8);
  }

  .form__select::before {
    width: 20px;
    height: 11px;
  }

  .form__btn {
    width: 100%;
  }

  .form-sec__subtitle {
    font-size: 18px;
    line-height: 25px;
  }

  .form-sec {
    margin-top: 71px;
  }

  .form-sec::before {
    width: 100%;
    height: 279%;
    top: -83%;
    background-size: cover;
  }

  .form-sec.form-sec--two {
    margin-top: 110px;
  }

  .form-sec.form-sec--two .form-sec__wrapper {
    margin-top: 10px;
  }
}
.groups {
  margin-top: 230px;
}

.groups__title {
  text-align: center;
}

.groups__subtitle {
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  margin: 40px auto 0 auto;
  max-width: 590px;
}

.groups__wrapper {
  margin: 60px auto 0 auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 40px;
  column-gap: 20px;
  max-width: 1060px;
  position: relative;
}
.groups__wrapper::before {
  position: absolute;
  content: url("../img/groups-icon.svg");
  left: -192px;
  top: -165px;
}
.groups__wrapper::after {
  position: absolute;
  content: url("../img/groups-icon2.svg");
  right: -192px;
  bottom: -126px;
}

.groups-item {
  border: 2px dashed #F05A2C;
  background: #FFFFFF;
  border-radius: 24px;
  min-height: 550px;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-in-out;
}
.groups-item:hover {
  box-shadow: 6px 10px 25px rgba(236, 104, 56, 0.2);
}

.groups-item__top {
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px 10px 10px;
  font-weight: 400;
  font-size: 32px;
  line-height: 30px;
  color: #EC6838;
  font-family: "Neucha";
  box-shadow: 2px 2px 15px #E7E7E7;
}
.groups-item__top span {
  font-family: "Comfortaa";
  font-weight: 500;
  font-size: 22px;
  margin-top: 10px;
}

.groups-item__list {
  padding: 20px;
  margin-bottom: 30px;
}
.groups-item__list li {
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  margin-bottom: 10px;
  padding-left: 30px;
  position: relative;
}
.groups-item__list li::before {
  content: "";
  position: absolute;
  background-image: url("../img/list-icon.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 20.13px;
  height: 19.5px;
  left: 0;
  top: 4px;
}
.groups-item__list li:last-child {
  margin-bottom: 0;
}

.groups-item__price {
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  color: #FFFFFF;
  position: relative;
  margin-top: auto;
  margin-bottom: 45px;
  z-index: 2;
}
.groups-item__price::before {
  position: absolute;
  content: url("../img/btn-bg.svg");
  left: 50%;
  top: 55%;
  transform: translate(-50%, -50%);
  z-index: -1;
}
.groups-item__price span {
  z-index: 1;
}

.groups-item-color1 .groups-item__top {
  background: #F6D354;
}

.groups-item-color2 .groups-item__top {
  background: #FDB041;
}

.groups-item-color3 .groups-item__top {
  background: #EC6838;
  color: #F6D354;
}

.groups-item-color4 .groups-item__top {
  background: #FFC5D3;
}

@media (max-width: 1000px) {
  .groups {
    margin-top: 140px;
  }
}
@media (max-width: 720px) {
  .groups__wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media (max-width: 580px) {
  .groups {
    margin-top: 70px;
  }

  .groups-item__top {
    font-size: 28px;
  }

  .groups-item__list li {
    font-size: 18px;
    line-height: 27px;
  }

  .groups-item {
    min-height: auto;
  }

  .groups-item__price {
    font-size: 18px;
  }

  .groups__subtitle {
    font-size: 18px;
    line-height: 27px;
    margin: 20px auto 0 auto;
  }
}
.nutrition {
  margin-top: 212px;
  position: relative;
}
.nutrition::before {
  content: "";
  position: absolute;
  background-image: url("../img/nutrition-bg.svg");
  background-repeat: no-repeat;
  background-size: 100% auto;
  width: 100%;
  height: 500%;
  z-index: -1;
  top: -300px;
}

.nutrition__wrapper {
  background: #FFFFFF;
  border-radius: 24px;
  max-width: 1104px;
  margin: 0 auto;
  padding: 100px 120px 60px;
  position: relative;
}

.nutrition-decor {
  position: absolute;
}

.nutrition-decor {
  transform: scale(var(--scale)) rotate(var(--rotate));
  will-change: transform;
}

.decor1 {
  left: -147px;
  top: -107px;
  z-index: -1;
}

.decor2 {
  right: -50px;
  top: -49px;
}

.decor3 {
  right: -117px;
  bottom: -101px;
  transform: scale(var(--scale2)) rotate(var(--rotate2));
  will-change: transform;
}

.nutrition__title {
  text-align: center;
  color: #000000;
  margin-bottom: 60px;
}

.nutrition-item {
  margin-bottom: 38px;
}
.nutrition-item:last-child {
  margin-bottom: 0;
}

.nutrition-item__top {
  font-weight: 400;
  font-size: 46px;
  line-height: 51px;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 2;
}
.nutrition-item__top::before {
  position: absolute;
  content: "";
  border-bottom: 1px dashed #000000;
  width: 100%;
  bottom: 14px;
  z-index: -1;
}
.nutrition-item__top div {
  background: #FFFFFF;
}

.nutrition-item__name {
  padding-right: 10px;
  font-family: "Neucha";
}

.nutrition-item__time {
  padding-left: 10px;
  font-family: "Neucha";
}

.nutrition-item__descr {
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  margin-top: 20px;
}

@media (max-width: 880px) {
  .nutrition__wrapper {
    padding: 50px 40px 60px;
  }

  .nutrition-item__top {
    font-size: 40px;
    line-height: 35px;
  }

  .nutrition-item__descr {
    font-size: 18px;
    line-height: 27px;
  }
}
@media (max-width: 580px) {
  .nutrition-item__top {
    font-size: 30px;
    line-height: 32px;
  }

  .nutrition__title {
    margin-bottom: 40px;
  }

  .nutrition-item__descr {
    font-size: 16px;
  }

  .nutrition__wrapper {
    padding: 50px 20px;
  }

  .decor2 {
    right: -50px;
    top: -120px;
  }

  .decor1 {
    left: -63px;
    top: -194px;
  }

  .decor3 {
    right: -40px;
    bottom: -157px;
  }

  .nutrition .container {
    padding: 0;
  }

  .nutrition::before {
    width: 100%;
    height: 279%;
    top: -83%;
    background-size: cover;
  }
}
.our-team {
  margin-top: 207px;
}

.our-team__title {
  text-align: center;
}

.container-slider {
  margin-top: 50px;
}

.our-team__slider {
  position: relative;
}
.our-team__slider .circle-col__descr {
  margin-top: 10px;
}
.our-team__slider .circle-col__title {
  margin-top: 40px;
}
.our-team__slider .circle-col__img {
  width: 200px;
  height: 200px;
  position: relative;
}
.our-team__slider .circle-col__img::before {
  position: absolute;
  content: "";
  width: 240px;
  height: 240px;
  border: 2px dashed #F05A2C;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.our-team__slider .swiper {
  padding-top: 30px;
}

@media (max-width: 920px) {
  .our-team__slider .circle-col__img {
    width: 150px;
    height: 150px;
  }

  .our-team__slider .circle-col__img::before {
    width: 180px;
    height: 180px;
  }

  .our-team {
    margin-top: 150px;
  }

  .circle-col__title {
    font-size: 18px;
    margin-top: 30px;
  }
}
@media (max-width: 580px) {
  .container-slider .swiper-button-next {
    right: 10px;
  }

  .container-slider .swiper-button-prev {
    left: 10px;
  }

  .container-slider {
    margin-top: 30px;
  }
}
.question {
  margin-top: 163px;
  position: relative;
}
.question::before {
  position: absolute;
  content: url("../img/question-line.svg");
  left: 52%;
  transform: translateX(-50%);
  top: -343px;
  z-index: -1;
}

.question__wrapper {
  display: flex;
  position: relative;
  margin-top: 60px;
}

.question__img {
  position: absolute;
  left: -78px;
  top: 48px;
  transform: scale(var(--scale3)) rotate(var(--rotate3));
  will-change: transform;
  pointer-events: none;
}

.question__list {
  max-width: 760px;
  width: 100%;
  margin-left: auto;
  background-color: #fff;
}

.toggle__item {
  border-bottom: 2px solid #F05A2C;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.toggle__item:last-child {
  margin-bottom: 0;
}

.toggle__item-title {
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.toggle__item-title::before {
  content: "";
  background-image: url("../img/acc-icon.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 64px;
  min-width: 64px;
  height: 64px;
  display: block;
  margin-left: 5px;
  margin-right: 20px;
  transition: all 0.3s ease-in-out;
}
.toggle__item-title.toggle__item-title-active::before {
  transform: rotate(90deg);
}

.toggle__item-descr {
  font-weight: 400;
  font-size: 20px;
  line-height: 22px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-left: 91px;
  display: none;
}

@media (max-width: 1200px) {
  .question__img {
    opacity: 0.2;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .question::before {
    opacity: 0.4;
  }

  .question__list {
    max-width: 100%;
  }
}
@media (max-width: 820px) {
  .question {
    margin-top: 90px;
  }

  .toggle__item-title::before {
    width: 54px;
    min-width: 54px;
    height: 54px;
  }
}
@media (max-width: 580px) {
  .question {
    margin-top: 70px;
  }

  .question__list {
    background-color: transparent;
  }

  .question__wrapper {
    margin-top: 40px;
  }

  .toggle__item-title {
    font-size: 18px;
    line-height: 26px;
  }

  .toggle__item-descr {
    font-size: 16px;
    padding-left: 0;
  }

  .toggle__item-title::before {
    width: 44px;
    min-width: 44px;
    height: 44px;
  }
}
.reviews {
  margin-top: 227px;
}

.reviews__title {
  text-align: center;
}

.reviews__slider {
  position: relative;
}
.reviews__slider .swiper-button-next,
.reviews__slider .swiper-button-prev {
  top: 45%;
}

.swiper {
  padding-bottom: 40px;
}

.reviews-swiper .swiper-wrapper {
  align-items: center;
}
.reviews-swiper .swiper-slide {
  padding-left: 23px;
}

.reviews-item {
  background: #FFFFFF;
  border-radius: 24px;
  border: 2px solid #F05A2C;
  padding: 20px 20px 10px 20px;
  position: relative;
}
.reviews-item::before {
  content: "";
  position: absolute;
  background-image: url("../../img/reviews-icon.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 45px;
  height: 30px;
  left: -30px;
  bottom: -4px;
}
.reviews-item .grey {
  color: #666666;
}
.reviews-item .pink {
  color: #C1578A;
}
.reviews-item .green {
  color: #488A68;
}
.reviews-item .blue {
  color: #6E95A6;
}

.reviews-item__name {
  font-family: "Inter";
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}

.reviews-item__descr {
  font-family: "Inter";
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  margin-top: 10px;
}

.reviews-item__time {
  font-family: "Inter";
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  color: #666666;
  margin-top: 10px;
}

@media (max-width: 1080px) {
  .reviews {
    margin-top: 111px;
  }
}
@media (max-width: 580px) {
  .reviews__slider .swiper-button-next,
.reviews__slider .swiper-button-prev {
    display: none;
  }

  .swiper-pagination {
    bottom: 0px;
  }

  .reviews {
    margin-top: 80px;
  }
}
.contact {
  margin-top: 234px;
  position: relative;
}
.contact::before {
  position: absolute;
  content: url("../img/contact-line.svg");
  left: 51%;
  transform: translateX(-50%);
  top: -248px;
  z-index: -1;
}

.contact__wrapper {
  padding-left: 110px;
  display: flex;
  justify-content: space-between;
}

.contact__left {
  width: 524px;
}

.contact__info {
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  margin-top: 40px;
}
.contact__info a {
  color: #000;
}
.contact__info p {
  margin-bottom: 19px;
}
.contact__info p:last-child {
  margin-bottom: 0;
}

.contact__icon {
  margin-top: 40px;
}
.contact__icon a {
  transition: all 0.3s ease-in-out;
}
.contact__icon a:hover {
  filter: contrast(50%);
}

.contact__map {
  width: calc(100% - 550px);
  height: 462px;
  position: relative;
}
.contact__map::before {
  position: absolute;
  content: url("../img/contact__map.svg");
  right: -83px;
  top: -59px;
}
.contact__map iframe {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 24px;
}

@media (max-width: 1150px) {
  .contact__wrapper {
    padding-left: 0;
  }

  .contact__left {
    width: 380px;
  }

  .contact__map {
    width: calc(100% - 420px);
  }

  .contact::before {
    opacity: 0.4;
  }
}
@media (max-width: 940px) {
  .contact {
    margin-top: 140px;
  }
}
@media (max-width: 800px) {
  .contact__wrapper {
    flex-direction: column;
  }

  .contact__left {
    width: 100%;
  }

  .contact__map {
    width: 100%;
    margin-top: 20px;
  }
}
@media (max-width: 580px) {
  .contact {
    margin-top: 90px;
  }

  .contact__info {
    margin-top: 20px;
  }

  .contact__icon {
    margin-top: 20px;
  }

  .contact__map {
    height: 299px;
  }

  .contact__info {
    font-size: 18px;
    line-height: 21px;
  }
}
.footer {
  margin-top: 115px;
  padding-bottom: 45px;
  position: relative;
}
.footer::before {
  content: "";
  position: absolute;
  background-image: url("../img/footer-bg.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  width: 100%;
  height: 300%;
  z-index: -1;
  top: -145%;
}

.footer__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.link-polic {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  text-decoration-line: underline;
  display: block;
  margin-top: 65px;
  color: #000;
  position: relative;
  width: fit-content;
}
.link-polic::before {
  position: absolute;
  content: url("../img/link-polic.svg");
  right: -424px;
  top: -69px;
}

@media (max-width: 1150px) {
  .footer .nav {
    display: none;
  }
  .footer .phone-link {
    display: none;
  }

  .link-polic {
    margin-top: 0;
  }

  .footer::before {
    height: 460%;
    top: -200%;
  }
}
@media (max-width: 580px) {
  .footer__wrapper {
    flex-direction: column;
  }

  .footer::before {
    width: 100%;
    height: 279%;
    top: -83%;
    background-size: cover;
  }
}
.modal-wrap {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.486);
  z-index: 20000;
  opacity: 0;
  visibility: hidden;
  overflow-y: auto;
  transition: opacity 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-wrap.active {
  opacity: 1;
  visibility: visible;
}

.modal {
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow-y: auto;
  height: fit-content;
  border-radius: 16px;
  min-width: 846px;
  max-width: 846px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-height: 95vh;
}
.modal::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.modal__body {
  background: #F6D354;
  border-radius: 24px;
  padding: 60px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  overflow: hidden;
}
.modal__body::before {
  position: absolute;
  content: url("../img/modal-line.svg");
  width: 100%;
  height: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  pointer-events: none;
}
.modal__body .form__btn {
  margin: 40px auto 0 auto;
}

.modal__title {
  font-family: "Neucha";
  font-style: normal;
  font-weight: 400;
  font-size: 46px;
  line-height: 51px;
  text-transform: uppercase;
  color: #F05A2C;
  margin-bottom: 40px;
  text-align: center;
}

.modal__subtitle {
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  color: #F05A2C;
  margin: 40px auto 0 auto;
  max-width: 433px;
}

.modal__img {
  margin: 40px auto 0 auto;
}

@media (max-width: 900px) {
  .modal {
    min-width: 90%;
  }

  .modal__body {
    padding: 30px;
  }
}
@media (max-width: 580px) {
  .modal__title {
    font-size: 32px;
    line-height: 40px;
    z-index: 20;
    margin-bottom: 20px;
  }

  .modal__body::before {
    z-index: 0;
  }

  .form__input {
    height: 60px;
  }

  .modal__body {
    padding: 30px 20px;
  }

  .modal__subtitle {
    font-size: 18px;
    line-height: 26px;
    margin: 0 auto 0 auto;
  }

  .modal__img {
    height: 120px;
  }
  .modal__img img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
}