.about {
    margin-top: 200px;

    .container {
        padding-left: 123px;
        position: relative;

        &::before {
            position: absolute;
            content: url('../img/about-line.svg');
            left: -63px;
            top: -184px;
            pointer-events: none;
        }
    }
}


.about__wrapper {
    margin-top: 46px;
    display: flex;
    position: relative;
}

.about__descr {
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
    color: #000;
    max-width: 815px;

    p {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.about__img {
    position: absolute;
    right: -13px;
    top: -36px;
    transform: translateY(var(--position));
    will-change: transform;
    z-index: -1;
}

@media (max-width: 1260px) {
    .about__img {
        opacity: 0.5;
    }


}

@media (max-width: 1000px) {
    .about .container::before {
        opacity: 0.3;
    }

    .about__img {
        opacity: 0.3;
    }

    .about .container {
        padding-left: 15px;
    }

    .about {
        margin-top: 100px;
    }

    .about__descr {
        font-size: 18px;
    }

    .about__wrapper {
        margin-top: 20px;
    }


}

@media (max-width: 580px) {
    .title {
        font-size: 38px;
        line-height: 44px;
    }

    .about__descr {
        line-height: 25px;
        font-size: 16px;
    }

    .about .container::before {
        opacity: 0.2;
    }

    .about__img {
        opacity: 0.1;
    }
}