.modal-wrap {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.486);
    z-index: 20000;
    opacity: 0;
    visibility: hidden;
    overflow-y: auto;
    transition: opacity 0.3s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;

    &.active {
        opacity: 1;
        visibility: visible;
    }
}

.modal {
    @include scroll-hide;
    overflow-y: auto;
    height: fit-content;
    border-radius: 16px;
    min-width: 846px;
    max-width: 846px;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-height: 95vh;


}

.modal__body {
    background: #F6D354;
    border-radius: 24px;
    padding: 60px 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: relative;
    overflow: hidden;

    &::before {
        position: absolute;
        content: url('../img/modal-line.svg');
        width: 100%;
        height: 100%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        pointer-events: none;
    }

    .form__btn {
        margin: 40px auto 0 auto;
    }
}


.modal__title {
    font-family: 'Neucha';
    font-style: normal;
    font-weight: 400;
    font-size: 46px;
    line-height: 51px;
    text-transform: uppercase;
    color: #F05A2C;
    margin-bottom: 40px;
    text-align: center;
}

.modal__subtitle {
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
    text-align: center;
    color: #F05A2C;
    margin: 40px auto 0 auto;
    max-width: 433px;
}

.modal__img {
    margin: 40px auto 0 auto;
}


@media (max-width: 900px) {
    .modal {
        min-width: 90%;

    }

    .modal__body {
        padding: 30px;
    }
}

@media (max-width: 580px) {
    .modal__title {
        font-size: 32px;
        line-height: 40px;
        z-index: 20;
        margin-bottom: 20px;
    }

    .modal__body::before {
        z-index: 0;
    }

    .form__input {
        height: 60px;
    }

    .modal__body {
        padding: 30px 20px;
    }

    .modal__subtitle {
        font-size: 18px;
        line-height: 26px;
        margin: 0 auto 0 auto;
    }

    .modal__img {
        height: 120px;

        img {
            max-width: 100%;
            max-height: 100%;
            object-fit: cover;
        }
    }
}