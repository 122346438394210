* {
    font-family: "Comfortaa", sans-serif;
}

body {
    color: #000;

    &.scroll-hide {
        overflow: hidden;
    }
}

.container {
    max-width: 1310px;
    margin: 0 auto;
    padding: 0 15px;
}

.page {
    position: relative;
    overflow: hidden;
    min-height: 100vh;
}

.btn {
    background: linear-gradient(92.36deg, #F05A2C -16.09%, #EC6838 46.59%, #FDB041 113.31%);
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 22px;
    line-height: 25px;
    color: #FFFFFF;
    padding: 20px 55px;
    transition: all 0.3s ease-in-out;

    position: relative;

    &::before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        border-radius: 24px;
        border: 3px solid #F05A2C;
        opacity: 0;
        transition: all 0.3s ease-in-out;
    }

    &:hover {
        box-shadow: 4px 6px 15px rgba(240, 90, 44, 0.3);
    }

    &:active {
        &::before {
            opacity: 1;
        }
    }
}



.title {
    font-family: 'Neucha';
    font-weight: 400;
    font-size: 46px;
    line-height: 51px;
    text-transform: uppercase;
    color: #F05A2C;
}

.container-slider {
    max-width: 1400px;
    padding: 0 60px;

    .swiper-button-next {
        right: -50px;
    }

    .swiper-button-prev {
        left: -50px;
    }

    .swiper-button-next:after,
    .swiper-button-prev:after {
        color: #F05A2C;
        font-weight: 900;
        font-size: 37px;
    }

    .swiper-pagination-bullet {
        background: #FDB041;
        opacity: 1;
        width: 10px;
        height: 10px;
        margin: 0 5px !important;
    }

    .swiper-pagination-bullet-active {
        background: #F05A2C;
    }
}