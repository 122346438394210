.reviews {
    margin-top: 227px;
}

.reviews__title {
    text-align: center;
}


.reviews__slider {
    position: relative;

    .swiper-button-next,
    .swiper-button-prev {
        top: 45%;
    }
}

.swiper {
    padding-bottom: 40px;
}

.reviews-swiper {
    .swiper-wrapper {
        align-items: center;
    }

    .swiper-slide {
        padding-left: 23px;
    }
}

.reviews-item {
    background: #FFFFFF;
    border-radius: 24px;
    border: 2px solid #F05A2C;
    padding: 20px 20px 10px 20px;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        background-image: url('../../img/reviews-icon.png');
        background-repeat: no-repeat;
        background-size: cover;
        width: 45px;
        height: 30px;
        left: -30px;
        bottom: -4px;
    }

    .grey {
        color: #666666;
    }

    .pink {
        color: #C1578A;
    }

    .green {
        color: #488A68;
    }

    .blue {
        color: #6E95A6;
    }
}

.reviews-item__name {
    font-family: 'Inter';
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
}

.reviews-item__descr {
    font-family: 'Inter';
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    margin-top: 10px;
}

.reviews-item__time {
    font-family: 'Inter';
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: right;
    color: #666666;
    margin-top: 10px;
}


@media (max-width: 1080px) {
    .reviews {
        margin-top: 111px;
    }
}

@media (max-width: 580px) {

    .reviews__slider .swiper-button-next,
    .reviews__slider .swiper-button-prev {
        display: none;
    }

    .swiper-pagination {
        bottom: 0px;
    }

    .reviews {
        margin-top: 80px;
    }


}