.header {
    margin-top: 25px;
}

.header__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    &::before {
        position: absolute;
        content: url('../img/header-line.svg');
        left: 50%;
        transform: translateX(-50%);
        top: 87%;
    }
}

.nav__list {
    display: flex;
    align-items: center;
}

.nav__item {
    margin-right: 40px;

    &:last-child {
        margin-right: 0;
    }
}

.nav__link {
    font-family: 'Neucha';
    font-weight: 400;
    font-size: 22px;
    line-height: 24px;
    color: #000;
    position: relative;

    @media (min-width: 1024px) {
        &:hover {
            &::before {
                transform: translateY(0);
                opacity: 2;
            }
        }
    }

    &::before {
        position: absolute;
        content: '';
        width: 100%;
        background-color: #F05A2C;
        height: 2px;
        bottom: 0;
        left: 0;
        transform: translateY(5px);
        opacity: 0;
        transition: all 0.3s ease-in-out;
    }
}

.phone-link {
    font-family: 'Neucha';
    font-weight: 400;
    font-size: 22px;
    line-height: 24px;
    text-transform: capitalize;
    color: #000;
    position: relative;

    @media (min-width: 1024px) {
        &:hover {
            &::before {
                transform: translateY(0);
                opacity: 2;
            }
        }
    }

    &::before {
        position: absolute;
        content: '';
        width: 100%;
        background-color: #F05A2C;
        height: 2px;
        bottom: 0;
        left: 0;
        transform: translateY(5px);
        opacity: 0;
        transition: all 0.3s ease-in-out;
    }
}

.burger {
    height: 16px;
    width: 30px;
    justify-content: center;
    align-items: center;
    z-index: 350;
    cursor: pointer;
    transition: all .3s;
    display: none;
    position: relative;

    span {
        position: absolute;
        background: #EC6838;
        height: 3px;
        width: 30px;
        display: block;
        transition: all .3s;
        border-radius: 5px;

        &:nth-child(1) {
            top: 16px;
        }

        &:nth-child(2) {
            top: 50%;
            transform: translateY(-50%);
        }

        &:nth-child(3) {
            bottom: 16px;
        }
    }

    &.active {
        span {
            &:nth-child(1) {
                top: 6px;
                transform: rotate(45deg);
            }

            &:nth-child(2) {
                opacity: 0;
            }

            &:nth-child(3) {
                bottom: 7px;
                transform: rotate(-45deg);
            }
        }
    }
}


@media (max-width: 1280px) {
    .nav__item {
        margin-right: 20px;
    }
}

@media (max-width: 1150px) {
    .header {
        margin-top: 10px;
        position: fixed;
        background-color: rgba(255, 255, 255, 0);
        width: 100%;
        z-index: 222;
        margin-top: 0;
        padding: 10px 0 0 0;
        transition: all 0.3s ease-in-out;

        &.active {
            background-color: #fff;
        }

        .nav {
            position: fixed;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background: #F6D354;
            z-index: 100;
            padding: 50px 20px;
            transform: translateY(-100%);
            transition: all 0.3s ease-in-out;

            &.active {
                transform: translateY(0);
            }
        }

        .nav__list {
            flex-direction: column;

        }

        .phone-link {
            z-index: 2;
            margin: 0 auto;
            display: block;
            text-align: center;
        }

        .nav__item {
            margin-right: 0;
            margin-bottom: 20px;
        }

        .burger {
            display: block;
        }

        .header__wrapper::before {
            display: none;
        }

        .logo {
            height: 68px;
        }
    }



}