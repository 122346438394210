.form-sec {
    margin-top: 230px;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        background-image: url('../img/form-sec-bg.png');
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: center;
        width: 100%;
        height: 300%;
        z-index: -1;
        top: -60%;
    }

    .container {
        position: relative;

        &::before {
            position: absolute;
            content: url('../img/form-sec-icon.png');
            left: -67px;
            top: -169px;
        }
    }

    &.form-sec--two {
        margin-top: 350px;

        .container::before {
            display: none;
        }

        .form-sec__wrapper {
            margin-top: 60px;
        }

        .form-sec__left .form {
            margin-top: 20px;
        }
    }
}



.form-sec__wrapper {
    display: flex;
    justify-content: space-between;
}

.form-sec__left {
    max-width: 630px;
    width: 100%;

    .form {
        margin-top: 35px;

    }
}

.form-sec__title {}

.title {}

.form-sec__subtitle {
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
    color: #F05A2C;
    margin-top: 10px;
    max-width: 546px;
}

.form {
    width: 100%;
}

.form__input {
    width: 100%;
    height: 80px;
    margin-bottom: 20px;

    input {
        background: rgba(255, 255, 255, 0.6);
        border-radius: 24px;
        width: 100%;
        height: 100%;
        padding: 0 20px;
        font-weight: 500;
        font-size: 22px;
        line-height: 30px;
        color: #F05A2C;
        border: 1px solid #F05A2C;

        &::placeholder {
            color: #F05A2C;
        }
    }

    select {
        background: rgba(255, 255, 255, 0.6);
        border-radius: 24px;
        width: 100%;
        height: 100%;
        padding: 0 20px;
        font-weight: 500;
        font-size: 22px;
        line-height: 30px;
        padding-right: 50px;
        color: #F05A2C;
        border: 1px solid #F05A2C;
    }
}

.form__select {
    position: relative;

    &::before {
        content: '';
        position: absolute;
        background-image: url('../../img/arrow.svg');
        background-repeat: no-repeat;
        background-size: cover;
        width: 24px;
        height: 14px;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
    }
}

.phone {}

.btn {}

.form__btn {
    margin-top: 40px;
}

.form-sec__img {
    transform: translateX(var(--positionX));
    will-change: transform;
}

@media (max-width: 1000px) {
    .form-sec__img {
        width: 400px;

        img {
            max-width: 100%;
            max-height: 100%;
            object-fit: cover;
        }
    }

    .form-sec__wrapper {
        align-items: center;
    }

    .form-sec.form-sec--two {
        margin-top: 180px;
    }

    .form-sec.form-sec--two .form-sec__wrapper {
        margin-top: 40px;
    }

}

@media (max-width: 820px) {
    .form__input {
        height: 70px;
    }

    .form-sec__img {
        opacity: 0.1;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        pointer-events: none;
    }

    .form-sec .container::before {
        opacity: 0.4;
    }

    .form-sec__wrapper {
        position: relative;
    }

    .form-sec {
        margin-top: 110px;
    }
}

@media (max-width: 580px) {
    .form__input input {
        font-size: 18px;
        border-radius: 15px;
        background: rgba(255, 255, 255, 0.8);
    }

    .form__input select {
        font-size: 18px;
        border-radius: 15px;
        background: rgba(255, 255, 255, 0.8);
    }

    .form__select::before {
        width: 20px;
        height: 11px;
    }

    .form__btn {
        width: 100%;
    }

    .form-sec__subtitle {
        font-size: 18px;
        line-height: 25px;
    }

    .form-sec {
        margin-top: 71px;
    }

    .form-sec::before {
        width: 100%;
        height: 279%;
        top: -83%;
        background-size: cover;
    }

    .form-sec.form-sec--two {
        margin-top: 110px;
    }

    .form-sec.form-sec--two .form-sec__wrapper {
        margin-top: 10px;
    }
}