.our-team {
    margin-top: 207px;
}

.our-team__title {
    text-align: center;
}

.container-slider {
    margin-top: 50px;
}

.our-team__slider {
    position: relative;

    .circle-col__descr {
        margin-top: 10px;
    }

    .circle-col__title {
        margin-top: 40px;
    }

    .circle-col__img {
        width: 200px;
        height: 200px;
        position: relative;

        &::before {
            position: absolute;
            content: '';
            width: 240px;
            height: 240px;
            border: 2px dashed #F05A2C;
            border-radius: 50%;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .swiper {
        padding-top: 30px;
    }
}

@media (max-width: 920px) {
    .our-team__slider .circle-col__img {
        width: 150px;
        height: 150px;
    }

    .our-team__slider .circle-col__img::before {
        width: 180px;
        height: 180px;
    }

    .our-team {
        margin-top: 150px;
    }

    .circle-col__title {
        font-size: 18px;
        margin-top: 30px;
    }
}

@media (max-width: 580px) {
    .container-slider .swiper-button-next {
        right: 10px;
    }

    .container-slider .swiper-button-prev {
        left: 10px;
    }

    .container-slider {
        margin-top: 30px;
    }
}