.contact {
    margin-top: 234px;
    position: relative;

    &::before {
        position: absolute;
        content: url('../img/contact-line.svg');
        left: 51%;
        transform: translateX(-50%);
        top: -248px;
        z-index: -1;
    }
}

.contact__wrapper {
    padding-left: 110px;
    display: flex;
    justify-content: space-between;
}

.contact__left {
    width: 524px;
}

.contact__info {
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
    margin-top: 40px;

    a {
        color: #000;
    }

    p {
        margin-bottom: 19px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.contact__icon {
    margin-top: 40px;

    a {
        transition: all 0.3s ease-in-out;

        &:hover {
            filter: contrast(50%);
        }
    }
}

.contact__map {
    width: calc(100% - 550px);
    height: 462px;
    position: relative;

    &::before {
        position: absolute;
        content: url('../img/contact__map.svg');
        right: -83px;
        top: -59px;
    }

    iframe {
        width: 100%;
        height: 100%;
        border: none;
        border-radius: 24px;
    }
}


@media (max-width: 1150px) {
    .contact__wrapper {
        padding-left: 0;
    }

    .contact__left {
        width: 380px;
    }

    .contact__map {
        width: calc(100% - 420px);
    }

    .contact::before {
        opacity: 0.4;
    }
}

@media (max-width: 940px) {
    .contact {
        margin-top: 140px;
    }
}

@media (max-width: 800px) {
    .contact__wrapper {
        flex-direction: column;
    }

    .contact__left {
        width: 100%;
    }

    .contact__map {
        width: 100%;
        margin-top: 20px;
    }

}

@media (max-width: 580px) {
    .contact {
        margin-top: 90px;
    }

    .contact__info {
        margin-top: 20px;
    }

    .contact__icon {
        margin-top: 20px;
    }

    .contact__map {
        height: 299px;
    }

    .contact__info {
        font-size: 18px;
        line-height: 21px;
    }
}