.footer {
    margin-top: 115px;
    padding-bottom: 45px;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        background-image: url('../img/footer-bg.png');
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: center;
        width: 100%;
        height: 300%;
        z-index: -1;
        top: -145%;
    }
}

.footer__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.link-polic {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    text-decoration-line: underline;
    display: block;
    margin-top: 65px;
    color: #000;
    position: relative;
    width: fit-content;

    &::before {
        position: absolute;
        content: url('../img/link-polic.svg');
        right: -424px;
        top: -69px;
    }
}

@media (max-width: 1150px) {
    .footer {
        .nav {
            display: none;
        }

        .phone-link {
            display: none;
        }
    }

    .link-polic {
        margin-top: 0;
    }

    .footer::before {
        height: 460%;
        top: -200%;
    }
}

@media (max-width: 580px) {
    .footer__wrapper {
        flex-direction: column;
    }

    .footer::before {
        width: 100%;
        height: 279%;
        top: -83%;
        background-size: cover;
    }
}