.everyday-life {
    margin-top: 100px;

    .everyday-life__container {
        padding-left: 123px;
    }
}


.everyday-life__subtitle {
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
    color: #000;
    margin-top: 20px;
}

.everyday-life__list {
    margin-top: 40px;
    display: grid;
    grid-template-columns: repeat(4, auto);
    row-gap: 40px;
    column-gap: 25px;
    max-width: 1085px;

    li {
        font-weight: 500;
        font-size: 22px;
        line-height: 30px;
        color: #000;
        padding-left: 26px;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            background-image: url('../img/list-icon.svg');
            background-repeat: no-repeat;
            background-size: cover;
            width: 20.13px;
            height: 19.5px;
            left: 0;
            top: 4px;
        }
    }
}

.everyday-life__slider {
    margin-top: 60px;
    position: relative;

    .swiper {
        padding-bottom: 40px;
    }

    .swiper-button-next,
    .swiper-button-prev {
        top: 42%;
    }

    .swiper-slide {
        overflow: hidden;
        border-radius: 24px;

        img {
            max-width: 100%;
            max-height: 100%;
            object-fit: cover;
            border-radius: 24px;
            transition: all 2s ease-in-out;

            &:hover {
                transform: scale(1.1);
            }
        }
    }
}


@media (max-width: 1000px) {
    .everyday-life .everyday-life__container {
        padding-left: 15px;
    }

    .everyday-life__subtitle {
        font-size: 18px;
    }

    .everyday-life__list li {
        font-size: 18px;
    }

    .everyday-life__list {
        margin-top: 20px;
    }
}

@media (max-width: 930px) {
    .everyday-life__list {
        grid-template-columns: repeat(3, auto);
        row-gap: 20px;
    }
}

@media (max-width: 580px) {
    .everyday-life__list {
        grid-template-columns: repeat(1, auto);
        row-gap: 20px;
    }

    .everyday-life {
        margin-top: 70px;
    }

    .everyday-life__slider .swiper-button-next,
    .everyday-life__slider .swiper-button-prev {
        display: none;
    }

    .container-slider {
        padding: 0 15px;
        margin-top: 30px;
    }

    .everyday-life {
        .container-slider {
            margin-top: 30px;
        }
    }

    .everyday-life__slider {
        margin-top: 30px;
    }
}