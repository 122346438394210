.groups {
    margin-top: 230px;
}

.container {}

.groups__title {
    text-align: center;
}

.title {}

.groups__subtitle {
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
    text-align: center;
    margin: 40px auto 0 auto;
    max-width: 590px;
}


.groups__wrapper {
    margin: 60px auto 0 auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 40px;
    column-gap: 20px;
    max-width: 1060px;
    position: relative;

    &::before {
        position: absolute;
        content: url('../img/groups-icon.svg');
        left: -192px;
        top: -165px;
    }

    &::after {
        position: absolute;
        content: url('../img/groups-icon2.svg');
        right: -192px;
        bottom: -126px;
    }
}

.groups-item {
    border: 2px dashed #F05A2C;
    background: #FFFFFF;
    border-radius: 24px;
    min-height: 550px;
    display: flex;
    flex-direction: column;
    transition: all 0.3s ease-in-out;

    &:hover {
        box-shadow: 6px 10px 25px rgba(236, 104, 56, 0.2);
    }
}



.groups-item__top {
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px 10px 10px;
    font-weight: 400;
    font-size: 32px;
    line-height: 30px;
    color: #EC6838;
    font-family: 'Neucha';
    box-shadow: 2px 2px 15px #E7E7E7;

    span {
        font-family: 'Comfortaa';
        font-weight: 500;
        font-size: 22px;
        margin-top: 10px;
    }
}

.groups-item__list {
    padding: 20px;
    margin-bottom: 30px;

    li {
        font-weight: 500;
        font-size: 22px;
        line-height: 30px;
        margin-bottom: 10px;
        padding-left: 30px;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            background-image: url('../img/list-icon.svg');
            background-repeat: no-repeat;
            background-size: cover;
            width: 20.13px;
            height: 19.5px;
            left: 0;
            top: 4px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.groups-item__price {
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
    text-align: center;
    color: #FFFFFF;
    position: relative;
    margin-top: auto;
    margin-bottom: 45px;
    z-index: 2;

    &::before {
        position: absolute;
        content: url('../img/btn-bg.svg');
        left: 50%;
        top: 55%;
        transform: translate(-50%, -50%);
        z-index: -1;
    }

    span {
        z-index: 1;
    }
}

.groups-item-color1 {
    .groups-item__top {
        background: #F6D354;
    }
}

.groups-item-color2 {
    .groups-item__top {
        background: #FDB041;
    }
}

.groups-item-color3 {
    .groups-item__top {
        background: #EC6838;
        color: #F6D354;
    }
}

.groups-item-color4 {
    .groups-item__top {
        background: #FFC5D3;
    }
}


@media (max-width: 1000px) {
    .groups {
        margin-top: 140px;
    }
}

@media (max-width: 720px) {
    .groups__wrapper {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media (max-width: 580px) {
    .groups {
        margin-top: 70px;
    }

    .groups-item__top {
        font-size: 28px;
    }

    .groups-item__list li {
        font-size: 18px;
        line-height: 27px;
    }

    .groups-item {
        min-height: auto;
    }

    .groups-item__price {
        font-size: 18px;
    }

    .groups__subtitle {
        font-size: 18px;
        line-height: 27px;
        margin: 20px auto 0 auto;
    }
}