.promo {
    margin-top: 185px;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        background-image: url('../img/promo-bg.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 400%;
        z-index: -1;
        top: -135%;
    }

}

.promo__img {
    position: absolute;
    left: 49.5%;
    top: 70%;
    transform: translate(-50%, -50%);
    pointer-events: none;
}

.promo__wrapper {
    max-width: 706px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    text-align: center;
}

.promo__title {
    font-family: 'Neucha';
    font-weight: 400;
    font-size: 84px;
    line-height: 93px;
    text-transform: uppercase;
    color: #F05A2C;
    letter-spacing: 12px;
    display: flex;

    span {
        transform: translateY(-27px);
        display: block;
    }
}

.promo__subtitle {
    font-weight: 500;
    font-size: 22px;
    line-height: 25px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    margin-top: 5px;
}

.promo__descr {
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
    color: #000;
    margin-top: 40px;
}

.promo__btn {
    margin: 60px auto 0 auto;
}

@media (max-width: 860px) {
    .promo__img {
        display: none;
    }

    .promo__title {
        font-size: 72px;
    }

    .promo__subtitle {
        font-size: 18px;
    }

    .promo__descr {
        font-size: 18px;
        margin-top: 20px;
    }

    .promo {
        margin-top: 120px;
    }

    .btn {
        font-size: 18px;
        border-radius: 20px;
        padding: 16px 40px;
    }

    .promo__btn {
        margin: 40px auto 0 auto;
    }
}


@media (max-width: 580px) {
    .promo__title {
        font-size: 62px;
    }

    .promo__subtitle {
        font-size: 16px;
    }

    .promo__descr {
        font-size: 16px;
        line-height: 26px;
    }

    .btn {
        font-size: 16px;
        border-radius: 15px;
        padding: 15px 30px;
    }

    .promo::before {
        width: 100%;
        height: 279%;
        top: -83%;
        background-size: cover;
    }
}